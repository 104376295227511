exports.onRouteUpdate = ({ location, prevLocation }) => {
  // Check if the window object is available (e.g., to avoid errors during the build process)
  if (typeof window !== "undefined") {
    // Load the Mailchimp script only once
    if (!window.mailchimpScriptLoaded) {
      const script = document.createElement("script")
      script.id = "mcjs"
      script.async = true
      script.src =
        "https://chimpstatic.com/mcjs-connected/js/users/9189cc74acefd08abbc393341/89fd7d60f5101d4fa7a50e5b9.js"
      script.onload = () => {
        window.mailchimpScriptLoaded = true
      }
      document.body.appendChild(script)
    }
  }
}
